* {
  margin: 0;
  padding: 0;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
  padding-bottom: 300px;
}

.box {
  display: flex;
  flex: 0.8;
  flex-direction: row;
  border-radius: 20px;
  min-width: 320px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  margin-top: 180px;
}

.box__left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 0.5;
  padding-top: 56px;
  padding-left: 107px;
  padding-bottom: 77px;
  border-radius: 20px 0 0 20px;
}

.box__titleBoxLeft {
  display: flex;
  flex: 0.15;
  flex-direction: column;
}

.box__right {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  position: relative;
  padding-top: 56px;
  padding-right: 107px;
  padding-bottom: 77px;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
}

.box__mainTitleLeft {
  max-width: 358px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 6px;
}

.box__paragraph {
  max-width: 424px;
  font-size: 14px;
  margin-bottom: 40px;
  line-height: 150%;
}

.box__subTitle {
  display: flex;
  flex: 0.05;
  margin-bottom: 24px;
}

.box__noteTitle {
  display: flex;
  flex: 0.05;
  margin-bottom: 24px;
  font-size: 18px;
}

.box__nameBox {
  display: flex;
  flex-wrap: wrap;
  flex: 0.1;
}

.box__inputShort {
  flex: 0.3;
  margin-bottom: 20px;
}

.invisbleBox {
  flex: 0.1;
  min-width: 17px;
}

.inputLong {
  display: flex;
  flex: 0.1;
  flex-direction: column;
}

.box__inputLong {
  display: flex;
  flex: 0.1;
  margin-bottom: 20px;
}

.box__inputField {
  flex: 0.7;
}

.box__btnLeft {
  display: flex;
  flex: 0.1;
  margin-top: 48px;
}

.box__btnLeftInnerBox {
  display: flex;
  justify-content: flex-end;
  flex: 0.7;
  text-align: right;
}

.box__mainTitleRight {
  display: flex;
  justify-content: flex-end;
  flex: 2;
  align-items: flex-end;
  font-size: 24px;
  font-weight: 600;
}

.box__mainTitleRightInnerBox {
  flex: 0.7;
}

.box__btnRight {
  display: flex;
  justify-content: flex-end;
  flex: 0.1;
  margin-top: 48px;
}

.box__btnRightInnerBox {
  flex: 0.7;
}

.box__img {
  position: absolute;
  right: -100px;
  top: -100px;
}

.box__imgPostion {
  position: absolute;
  right: 30px;
  top: 30px;
}
.box__img_shoresofpanama {
  position: absolute;
  width: 400px;
  right: 30px;
  top: 30px;
}

.alert {
  display: flex;
  margin-right: 30%;
}

.alertBox {
  flex: 1;
}

.box__importantNotice {
  max-width: 424px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 150%;
  font-weight: 500;
  background-color: var(--lightGrey);
  padding: 10px;
  border-radius: 5px;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    flex: 1;
    flex-direction: row;
    margin-top: 180px;
  }

  .box__right {
    padding-top: 56px;
    padding-right: 87px;
  }

  .box__mainTitleRight {
    margin-left: 20px;
  }

  .box__btnRight {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .box {
    flex-direction: column !important;
    flex-wrap: wrap;
    flex: 1;
    flex-direction: row;
    border-radius: 0px;
    margin-top: 44px;
  }

  .box__left {
    flex: 1;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 77px;
    border-radius: 0;
  }

  .box__right {
    flex: 1;
    padding-top: 56px;
    padding-right: 20px;
    padding-bottom: 77px;
    border-radius: 0;
  }

  .box__mainTitleLeft {
    margin-left: 20px;
  }

  .box__paragraph {
    margin-left: 20px;
  }

  .box__subTitle {
    margin-left: 20px;
  }

  .box__nameBox {
    margin-left: 20px;
  }

  .inputLong {
    margin-left: 20px;
  }

  .box__mainTitleRight {
    margin-left: 40px;
    justify-content: flex-start;
  }

  .box__btnLeft {
    margin-left: 20px;
  }

  .box__btnLeftInnerBox {
    justify-content: flex-start;
  }

  .box__btnRight {
    margin-left: 40px;
    margin-top: 48px;
    justify-content: flex-start;
  }

  .box__img {
    display: none;
  }

  .box__imgPostion {
    display: none;
  }

  .alert {
    margin-right: 30%;
    margin-left: 20px;
  }

  .alertBox {
    flex: 1;
    min-width: 181.98px;
  }

  .container {
    padding-bottom: 0px;
  }

  .box__importantNotice {
    margin-left: 20px;
  }
}

.container_option {
  padding: 0px 200px;
}

.container_option .back_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #d9d9d9;
  font-size: 20px;
  font-weight: bold;
  border-radius: 100%;
  margin-bottom: 30px;
}

.container_option .box {
  box-shadow: none;
  padding: 30px;
  background: #fff;
  overflow: hidden;
}

.container_option .box form {
  width: 100%;
}

.radio {
  display: none !important;
}

.container_option .box__inputField label {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  overflow: visible;
  font-weight: 600;

}

.container_option .box__inputField label span {
  order: 1;
}

.container_option .box__inputField label span+div {
  margin-right: 10px;
  flex: 0 0 25px;
}

.container_option .box__inputField input {
  width: 25px;
}

.container_option .box__subTitle {
  font-size: 30px;
  margin-bottom: 50px;
}

.container_option .box__btnLeftInnerBox {
  text-align: left;
}

.container_option .box__btnLeft {
  margin-top: 30px;
}

.container_option .shapeBg {
  position: absolute;
}

.main_wrapper {
  background-color: #fff;
}

.shape_bg {
  position: absolute;
  right: 0px;
  bottom: -33px;
  right: -33px;
  width: 75%;
}

.container_option .box form {
  position: relative;
  position: relative;
  height: 630px;
}

.container_option .back_arrow a {
  margin-top: -5px;
}